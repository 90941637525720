<div class="card">
  <div class="card-title">
    <span class="material-icons-round card-header-icon">graphic_eq&nbsp;</span>
    Operational Parameters
  </div>

  <div class="card-body">
    <div class="accordion">
      <div class="accordion-item" id="power"  *ngIf="containsType(OPERATIONAL_PARAMETERS_GRAPH.ENERGY)">
        <co2-operational-parameters-item
          itemId="power"
          [headerText]="energyType === 'FUEL_GAS' ? 'Consumed Fuel Gas' : 'Consumed Power'"
          [isLoading]="powerGraphIsLoading"
          [graphInputs]="powerGraphInputs"
          [hasLoadingError]="hasPowerGraphLoadingError"
          (reloadGraphData)="reloadPowerGraphData.emit()"
          (toggleGraph)="onToggleGraph($event, OPERATIONAL_PARAMETERS_GRAPH.ENERGY)">
        </co2-operational-parameters-item>
      </div>

      <div class="accordion-item" id="water-flowrate" *ngIf="containsType(OPERATIONAL_PARAMETERS_GRAPH.WATER_FLOWRATES)">
        <co2-operational-parameters-item
          itemId="water-flowrate"
          headerText="Water Flowrate"
          [isLoading]="waterFlowratesGraphIsLoading"
          [graphInputs]="waterFlowratesGraphInputs"
          [hasLoadingError]="hasWaterFlowratesGraphLoadingError"
          (reloadGraphData)="reloadWaterFlowratesGraphData.emit()"
          (toggleGraph)="onToggleGraph($event, OPERATIONAL_PARAMETERS_GRAPH.WATER_FLOWRATES)">
        </co2-operational-parameters-item>
      </div>

      <div class="accordion-item" id="pressure" *ngIf="containsType(OPERATIONAL_PARAMETERS_GRAPH.SUCTION_AND_DISCHARGE_PRESSURE)">
        <co2-operational-parameters-item
          itemId="pressure"
          headerText="Suction / Discharge Pressure"
          [isLoading]="pressureGraphIsLoading"
          [graphInputs]="pressureGraphInputs"
          [hasLoadingError]="hasPressureGraphLoadingError"
          (reloadGraphData)="reloadPressureGraphData.emit()"
          (toggleGraph)="onToggleGraph($event, OPERATIONAL_PARAMETERS_GRAPH.SUCTION_AND_DISCHARGE_PRESSURE)">
        </co2-operational-parameters-item>
      </div>

      <div class="accordion-item" id="fuel-gas-flowrate" *ngIf="containsType(OPERATIONAL_PARAMETERS_GRAPH.FUEL_GAS_FLOWRATE)">
        <co2-operational-parameters-item
          itemId="fuel-gas-flowrate"
          headerText="Fuel Gas Flowrate"
          [isLoading]="fuelGasFlowratesGraphIsLoading"
          [graphInputs]="fuelGasFlowratesGraphInputs"
          [hasLoadingError]="hasFuelGasFlowratesGraphLoadingError"
          (reloadGraphData)="reloadFuelGasFlowratesGraphData.emit()"
          (toggleGraph)="onToggleGraph($event, OPERATIONAL_PARAMETERS_GRAPH.FUEL_GAS_FLOWRATE)">
        </co2-operational-parameters-item>
      </div>

      <div class="accordion-item" id="delivered-power"  *ngIf="containsType(OPERATIONAL_PARAMETERS_GRAPH.DELIVERED_POWER)">
        <co2-operational-parameters-item
          itemId="delivered-power"
          headerText="Delivered Power"
          [isLoading]="deliveredPowerGraphIsLoading"
          [graphInputs]="deliveredPowerGraphInputs"
          [hasLoadingError]="hasDeliveredPowerGraphLoadingError"
          (reloadGraphData)="reloadDeliveredPowerGraphData.emit()"
          (toggleGraph)="onToggleGraph($event, OPERATIONAL_PARAMETERS_GRAPH.DELIVERED_POWER)">
        </co2-operational-parameters-item>
      </div>


      <div class="accordion-item" id="process-gas-flowrate"  *ngIf="containsType(OPERATIONAL_PARAMETERS_GRAPH.PROCESS_GAS_FLOWRATE)">
        <co2-operational-parameters-item
          itemId="process-gas-flowrate"
          headerText="Process Gas Flowrate"
          [isLoading]="processGasFlowrateGraphIsLoading"
          [graphInputs]="processGasFlowrateGraphInputs"
          [hasLoadingError]="hasProcessGasFlowrateGraphLoadingError"
          (reloadGraphData)="reloadProcessGasFlowrateGraphData.emit()"
          (toggleGraph)="onToggleGraph($event, OPERATIONAL_PARAMETERS_GRAPH.PROCESS_GAS_FLOWRATE)">
        </co2-operational-parameters-item>
      </div>

        <div class="accordion-item" id="suction-discharge-pressure"  *ngIf="containsType(OPERATIONAL_PARAMETERS_GRAPH.SUCTION_DISCHARGE_PRESSURE)">
        <co2-operational-parameters-item
          itemId="suction-discharge-pressure"
          headerText="Suction / Discharge Pressure"
          [isLoading]="suctionDischargePressureGraphIsLoading"
          [graphInputs]="suctionDischargePressureGraphInputs"
          [hasLoadingError]="hasSuctionDischargePressureGraphLoadingError"
          (reloadGraphData)="reloadSuctionDischargePressureGraphData.emit()"
          (toggleGraph)="onToggleGraph($event, OPERATIONAL_PARAMETERS_GRAPH.SUCTION_DISCHARGE_PRESSURE)">
        </co2-operational-parameters-item>
      </div>

      <div class="accordion-item" id="suction-discharge-temperature"  *ngIf="containsType(OPERATIONAL_PARAMETERS_GRAPH.SUCTION_DISCHARGE_TEMPERATURE)">
        <co2-operational-parameters-item
          itemId="suction-discharge-temperature"
          headerText="Suction / Discharge Temperature"
          [isLoading]="suctionDischargeTemperatureGraphIsLoading"
          [graphInputs]="suctionDischargeTemperatureGraphInputs"
          [hasLoadingError]="hasSuctionDischargeTemperatureGraphLoadingError"
          (reloadGraphData)="reloadSuctionDischargeTemperatureGraphData.emit()"
          (toggleGraph)="onToggleGraph($event, OPERATIONAL_PARAMETERS_GRAPH.SUCTION_DISCHARGE_TEMPERATURE)">
        </co2-operational-parameters-item>
      </div>

    </div>
  </div>
</div>
