<div class="card">
    <div class="card-title">
      <span class="material-icons-round card-header-icon">local_fire_department&nbsp;</span>
      Monthly flaring
    </div>
  
    <div class="card-body">
      <div class="title">
        CO2 Emissions
      </div>
        <div class="subtitle">Historical</div>
        <div class="co2-graph-body">
            <div class="co2-graph">
                <co2-ui-graph  [options]="co2EmissionMonthlyInputs" [noAvailableData]="noAvailableEmissionMonthly"></co2-ui-graph>
            </div>
          </div>
    </div>

    <div class="card-body">
      <div class="title">
        Flow Rate
      </div>
        <div class="subtitle">Historical</div>

        <div class="co2-graph-body">
            <div class="co2-graph">
                <co2-ui-graph  [options]="flowMonthlyInputs" [noAvailableData]="noAvailableFlareMonthly"></co2-ui-graph>
            </div>
          </div>
    </div>
</div>
