import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '@environments/environment';
import { OperationalParametersResponse } from '../operational-parameters/operational-parameters.model';
import { FlareConfig } from "./flaring-modal";
import { FlareType } from "../../containers/general-dashboard/general-dashboard.container.const";
import { getUserTimezone } from "../../utils";

@Injectable({
    providedIn: 'root'
})
export class FlaringService {

    constructor(private http: HttpClient) { }


    getConfig(affiliate: string, fpsoName: string, equipmentType: string): Observable<FlareConfig> {
      return this.http.get<FlareConfig>(
        `${environment.apiUrl}/${affiliate}/${fpsoName}/flaring/config/${FlareType.Flare}/${equipmentType}`
      );
    }

    getHourlyFlaring(affiliate: string, fpsoName: string, equipmentType: string, type: string): Observable<any> {
        const params = new HttpParams().set('type', type)
          .append('timezone', getUserTimezone());

        return this.http.get<OperationalParametersResponse[]>(
            `${environment.apiUrl}/${affiliate}/${fpsoName}/flaring/hourly/${FlareType.Flare}/${equipmentType}`,
            { params: params }
        );
    }

    getDailyFlaring(affiliate: string, fpsoName: string, equipmentType: string, type: string): Observable<any> {
        const params = new HttpParams().set('type', type);

        return this.http.get<OperationalParametersResponse[]>(
            `${environment.apiUrl}/${affiliate}/${fpsoName}/flaring/daily/${FlareType.Flare}/${equipmentType}`,
            { params: params }
        );
    }

    getMonthlyFlaring(affiliate: string, fpsoName: string, equipmentType: string, type: string): Observable<any> {
        const params = new HttpParams().set('type', type);

        return this.http.get<OperationalParametersResponse[]>(
            `${environment.apiUrl}/${affiliate}/${fpsoName}/flaring/monthly/${FlareType.Flare}/${equipmentType}`,
            { params: params }
        );
    }
}
