import { Component, Input } from '@angular/core';
import { FlaringService } from 'src/modules/app/services/flaring/flaring.service';
import { cloneObject } from 'src/modules/app/utils/object-manipulation/object-manipulation';
import { POWER_GRAPH } from '../../compressors';
import { AppSandbox } from 'src/modules/app/app.sandbox';
import { CommonGraphsService } from 'src/modules/app/services/common-graphs/common-graphs.service';
import { Router } from '@angular/router';
import { DateTimePickerProperties } from 'src/modules/app/utils/date-time-picker/date-time-picker';
import { TabItem } from 'src/modules/app/types';
import { EChartsOption } from 'echarts';
import { FlareType } from '../../general-dashboard/general-dashboard.container.const';

@Component({
  selector: 'co2-monthly-flaring',
  templateUrl: './monthly-flaring.component.html',
  styleUrls: ['./monthly-flaring.component.scss']
})
export class MonthlyFlaringComponent {
  readonly FLARING_TYPE = "FLARE";

  @Input() type: any;
  @Input () fpso: string = '';
  @Input () affiliate: string = '';
  equipmentNamesByType!: Map<string, string[]>;
  allEquipmentNames!: string[];
  subTabs!: TabItem[];
  performanceDriftCompGraph: any;
  activeTab: any;
  flaringData: any;
  co2EmissionMonthlyInputs!: EChartsOption;
  flowMonthlyInputs!: EChartsOption;
  noAvailableEmissionMonthly : boolean = false;
  noAvailableFlareMonthly : boolean = false;
  datePickerProperties!: DateTimePickerProperties;
  graphMetadata = [{"LAE":{"color":"#2196F3","yAxisName":"electrical power optimum (kW)","yAxisUnit":"kW"}},
  {"X3/Over the norm":{"color":"#8c9fae","yAxisName":"electrical power optimum (kW)","yAxisUnit":"kW"}},
  {"Full Flare":{"color":"#FFCC80","yAxisName":"electrical power optimum (kW)","yAxisUnit":"kW"}}]


  constructor(
    public sb: AppSandbox,
    private flaringService: FlaringService,
    private graphsService: CommonGraphsService,
    public router: Router) {
  }

  ngOnChanges() {
    this.flaringService.getMonthlyFlaring(this.affiliate, this.fpso, this.type, FlareType.EMISSIONS_MONTHLY).subscribe((data:any)=>{

      if(data.length) {
      this.co2EmissionMonthlyInputs = cloneObject(POWER_GRAPH);
      this.co2EmissionMonthlyInputs = { ...this.graphsService.updateBarGraph(this.co2EmissionMonthlyInputs, data, 'CO2 emissions ktCO2/y', this.graphMetadata) };
    } else {
      this.noAvailableEmissionMonthly = true;
    }
    })
    this.flaringService.getMonthlyFlaring(this.affiliate, this.fpso, this.type, FlareType.FLOW_RATE_MONTHLY).subscribe((data:any)=>{

      if(data.length) {
      this.flowMonthlyInputs = cloneObject(POWER_GRAPH);
      this.flowMonthlyInputs = { ...this.graphsService.updateBarGraph(this.flowMonthlyInputs, data, 'Flow Rate kSm3/d', this.graphMetadata) };
    } else {
      this.noAvailableFlareMonthly = true;
    }
    })

  };


}
