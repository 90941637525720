import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
/* @vite-ignore */
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { Subscription } from "rxjs";
import { Store } from "@ngxs/store";
import { ActiveAffiliateState, ActiveFpsoState } from "../../../statemanagement";

@Component({
  selector: 'co2-setting-page',
  templateUrl: './setting-page.component.html',
  styleUrls: ['./setting-page.component.scss']
})
export class SettingPageComponent implements OnInit, OnDestroy{

  basePath = '../../../../assets/settings';
  methodologySource: string = 'calculation_methods.pdf';
  baselinesSource: string = 'baselines.pdf';
  tagsSource: string = 'tags.pdf';
  selectedPdf: string = '';
  title:string='';
  totalPages!: number;
  page: number = 1;
  isLoaded: boolean = false;
  routeSubscribe!: Subscription;

  constructor(private route: ActivatedRoute, private store: Store) { }


  ngOnInit() {
    this.routeSubscribe = this.route.url.subscribe(url => {
      // Check the last segment of the route to determine which PDF to select
      const routeSegment = url[url.length - 1].path;
      if (routeSegment === 'methodology') {
        this.page = 1;
        this.selectedPdf = `${this.basePath}/COMMON/${this.store.selectSnapshot(ActiveAffiliateState.get)}/${this.methodologySource}`;
        this.title = 'Methodology';
      } else if (routeSegment === 'baselines') {
        this.page = 1;
        this.selectedPdf = `${this.basePath}/COMMON/${this.store.selectSnapshot(ActiveAffiliateState.get)}/${this.baselinesSource}`;
        this.title = 'Description baseline';
      } else if (routeSegment === 'tags') {
        this.page = 1;
        this.selectedPdf = `${this.basePath}/${this.store.selectSnapshot(ActiveFpsoState.get)}/${this.tagsSource}`;
        this.title = 'PI tags';

      }
    });
  }

  callBackFn(pdf: PDFDocumentProxy) {
    this.totalPages = pdf.numPages;
    this.isLoaded = true;
  }

  nextPage() {
    this.page++;
  }

  prevPage() {
    this.page--;
  }

  ngOnDestroy(): void {
    this.routeSubscribe.unsubscribe();
  }

  isPiTags() {
    return this.title === 'PI tags';
  }

  downloadPdf() {
    if (!this.selectedPdf || !this.isPiTags()) return;
    const link = document.createElement('a');
    link.href = this.selectedPdf;
    link.download = `${this.title}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
