import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { AppSandbox } from '../../app.sandbox';
import { environment } from '@environments/environment';
import { CompressorIndicator } from '../../types';
import { AppInsightsService, PendingRequestsService } from '../../services';
import { RequestBehaviorSubjects } from "./loader.interface";
import { Observable } from "rxjs";
import { OperationalParametersType } from "../../types/operational-parameters.type";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(private sb: AppSandbox,
              private appInsightService: AppInsightsService,
              private pendingRequestsService: PendingRequestsService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.toggleLoaderOrError(request.url, request.params, true);

    return new Observable(observer => {
      const subscription = next.handle(request).subscribe({
        next: event => {
          if (event instanceof HttpResponse) {
            this.toggleLoaderOrError(request.url, request.params, false, false);
            observer.next(event);
          }
        },

        error: error => {
          this.toggleLoaderOrError(request.url, request.params, false, true);
          this.appInsightService.logException(error);
          observer.error(error);
        },

        complete: () => {
          this.toggleLoaderOrError(request.url, request.params, false);
          observer.complete();
        }
      });

      this.pendingRequestsService.handleNewRequest(request, subscription);

      // Case when request is cancelled
      return () => {
        this.toggleLoaderOrError(request.url, request.params, false);
        subscription.unsubscribe();
      };
    });
  }

  private toggleLoaderOrError(requestUrl: string, requestParams: HttpParams, showLoader: boolean, showError?: boolean): void {
    const fpso = this.sb.getActiveFpso();
    const affiliate = this.sb.getActiveAffiliate();
    let dataLoadersList: RequestBehaviorSubjects[] = [
      {
        requestUrl: `${ environment.apiUrl }/${ affiliate }/${ fpso }/general-dashboard/overview/total-co2`,
        responseBehaviorSubjects: {
          loader: this.sb.totalCo2YearlyEmissionLoader$,
          error: this.sb.totalCo2YearlyEmissionError$
        }
      },
      {
        requestUrl: `${ environment.apiUrl }/${ affiliate }/${ fpso }/compressors/influential-factors`,
        responseBehaviorSubjects: { loader: this.sb.compressorsAsvDataLoader$, error: this.sb.compressorsAsvDataError$ }
      },
      {
        requestUrl: `${ environment.apiUrl }/compressors/overview-kpis`,
        responseBehaviorSubjects: {
          loader: this.sb.compressorsOverviewKpisLoader$,
          error: this.sb.compressorsOverviewKpisError$
        }
      },
      {
        requestUrl: `${ environment.apiUrl }/${ affiliate }/${ fpso }/compressors/cross-plots`,
        responseBehaviorSubjects: {
          loader: this.sb.compressorCrossPlotLoader$,
          error: this.sb.compressorCrossPlotError$
        }
      },
      {
        requestUrl: `${ environment.apiUrl }/${ affiliate }/${ fpso }/turbogenerators/power-reserve`,
        responseBehaviorSubjects: {
          loader: this.sb.turbogenPowerReserveLoader$,
          error: this.sb.turbogenPowerReserveError$
        }
      },
      {
        requestUrl: `${ environment.apiUrl }/${ affiliate }/${ fpso }/turbogenerators/co2-factor-data`,
        responseBehaviorSubjects: {
          loader: this.sb.turbogenCo2FactorDataLoader$,
          error: this.sb.turbogenCo2FactorDataError$
        }
      },
      {
        requestUrl: `${ environment.apiUrl }/${ affiliate }/${ fpso }/cross-plots`,
        responseBehaviorSubjects: { loader: this.sb.turbogenCrossPlotLoader$, error: this.sb.turbogenCrossPlotError$ }
      },
      {
        requestUrl: `${ environment.apiUrl }/${ affiliate }/${ fpso }/turbogenerators/power-running-turbines`,
        responseBehaviorSubjects: {
          loader: this.sb.turbogenPowerTurbinesLoader$,
          error: this.sb.turbogenPowerTurbinesError$
        }
      },
      {
        requestUrl: `${ environment.apiUrl }/${ affiliate }/${ fpso }/turbogenerators/performance-drift`,
        responseBehaviorSubjects:
          { loader: this.sb.turbogenPerformanceDriftLoader$, error: this.sb.turbogenPerformanceDriftError$ }
      },
      {
        requestUrl: `${ environment.apiUrl }/${ affiliate }/${ fpso }/turbogenerators/hourly-performance-drift`,
        responseBehaviorSubjects:
          { loader: this.sb.turbogenHourlyPerformanceDriftLoader$, error: this.sb.turbogenHourlyPerformanceDriftError$ }
      },
      {
        requestUrl: `${ environment.apiUrl }/${ affiliate }/${ fpso }/pumps/cross-plots`,
        responseBehaviorSubjects:
          { loader: this.sb.turbogenPerformanceDriftLoader$, error: this.sb.turbogenPerformanceDriftError$ }
      },
      {
        requestUrl: `${ environment.apiUrl }/${ affiliate }/${ fpso }/performance-drift/hourly`,
        responseBehaviorSubjects: {
          loader: this.sb.pumpsPerformanceDriftLoader$,
          error: this.sb.pumpsPerformanceDriftError$
        }
      },
      {
        requestUrl: `${ environment.apiUrl }/${ affiliate }/${ fpso }/performance-drift/hourly`,
        responseBehaviorSubjects: {
          loader: this.sb.pumpsHourlyPerformanceDriftLoader$,
          error: this.sb.pumpsHourlyPerformanceDriftError$
        }
      },
      {
        requestUrl: `${ environment.apiUrl }/${ affiliate }/${ fpso }/alerting/latest`,
        responseBehaviorSubjects: { loader: this.sb.alertTableLoader$, error: this.sb.alertTableError$ }
      } ];

    this.loaderOrErrorForInfluentialFactors(requestUrl, requestParams, dataLoadersList, showLoader, showError);
    this.loaderOrErrorForOperationalParameters(requestUrl, requestParams, dataLoadersList, showLoader, showError);
  }

  private loaderOrErrorForInfluentialFactors(requestUrl: string, requestParams: HttpParams, dataLoadersList: RequestBehaviorSubjects[], showLoader: boolean, showError: undefined | boolean) {
    let loader, error;
    const fpso = this.sb.getActiveFpso();
    const affiliate = this.sb.getActiveAffiliate();
    // Handle the case Asv/polytropic data that share the same endpoint with different requestParams
    if (requestUrl === `${ environment.apiUrl }/${ affiliate }/${ fpso }/compressors/influential-factors` &&
      requestParams.get('compressor_indicator_type') === CompressorIndicator.POLYTROPIC_EFFICIENCY) {
      loader = this.sb.compressorsPolytropicDataLoader$;
      error = this.sb.compressorsPolytropicDataError$;
    } else if (requestUrl === `${ environment.apiUrl }/${ affiliate }/${ fpso }/compressors/influential-factors` &&
      requestParams.get('compressor_indicator_type') === CompressorIndicator.POWER) {
      loader = this.sb.compressorsPowerDataLoader$;
      error = this.sb.compressorsPowerDataError$;
    } else {
      loader = this.getValueByKey(requestUrl, dataLoadersList)?.loader;
      error = this.getValueByKey(requestUrl, dataLoadersList)?.error;
    }

    if (loader) loader.next(showLoader);
    if (error && showError !== undefined) error.next(showError)
  }

  private loaderOrErrorForOperationalParameters(requestUrl: string, requestParams: HttpParams, dataLoadersList: RequestBehaviorSubjects[], showLoader: boolean, showError: undefined | boolean) {
    let loader, error;

    if (requestUrl.indexOf('/operational-parameters') > 0 && requestParams.get('type') === OperationalParametersType.ENERGY) {
      loader = this.sb.pumpsPowerDataLoader$;
      error = this.sb.pumpsPowerDataError$;
    } else if (requestUrl.indexOf('/operational-parameters') > 0 && requestParams.get('type') === OperationalParametersType.WATER_FLOWRATES)  {
      loader = this.sb.pumpsWaterFlowratesDataLoader$;
      error = this.sb.pumpsWaterFlowratesDataError$;
    } else if (requestUrl.indexOf('/operational-parameters') > 0 && requestParams.get('type') === OperationalParametersType.SUCTION_AND_DISCHARGE_PRESSURE)  {
      loader = this.sb.pumpsPressureDataLoader$;
      error = this.sb.pumpsPressureDataError$;
    } else if (requestUrl.indexOf('/operational-parameters') > 0 && requestParams.get('type') === OperationalParametersType.DELIVERED_POWER)  {
      loader = this.sb.turbogenDeliveredPowerDataLoader$;
      error = this.sb.turbogenDeliveredPowerDataError$;
    } else if (requestUrl.indexOf('/operational-parameters') > 0 && requestParams.get('type') === OperationalParametersType.FUEL_GAS_FLOWRATE)  {
      loader = this.sb.turbogenFuelGasFlowratesDataLoader$;
      error = this.sb.turbogenFuelGasFlowratesDataError$;
    } else if (requestUrl.indexOf('/operational-parameters') > 0 && requestParams.get('type') === OperationalParametersType.PROCESS_GAS_FLOWRATE)  {
      loader = this.sb.compressorProcessGasFlowrateDataLoader$;
      error = this.sb.compressorProcessGasFlowrateDataError$;
    } else if (requestUrl.indexOf('/operational-parameters') > 0 && requestParams.get('type') === OperationalParametersType.SUCTION_DISCHARGE_PRESSURE)  {
      loader = this.sb.compressorSuctionDischargePressureLoader$;
      error = this.sb.compressorSuctionDischargePressureError$;
    } else if (requestUrl.indexOf('/operational-parameters') > 0 && requestParams.get('type') === OperationalParametersType.SUCTION_DISCHARGE_TEMPERATURE)  {
      loader = this.sb.compressorSuctionDischargeTemperatureLoader$;
      error = this.sb.compressorSuctionDischargeTemperatureError$;
    } else {
      loader = this.getValueByKey(requestUrl, dataLoadersList)?.loader;
      error = this.getValueByKey(requestUrl, dataLoadersList)?.error;
    }

    if (loader) loader.next(showLoader);
    if (error && showError !== undefined) error.next(showError)
  }


  private getValueByKey(requestUrl: string, list: RequestBehaviorSubjects[]): any {
    const filteredElements =
      list.filter(entry => requestUrl.includes(entry.requestUrl));
    if (filteredElements && filteredElements.length > 0)
      return filteredElements[0].responseBehaviorSubjects;
    return {};
  }
}
