import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ScrollingModule } from '@angular/cdk/scrolling';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import {
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory,
} from './config';

import { AppRoutingModule } from './app-routing.module';
import {
  AppContainer,
  HeaderNavComponent,
  PrivateLayoutContainer,
  PublicLayoutContainer,
} from './containers';
import { NgxsModule } from '@ngxs/store';
import { environment } from '@environments/environment';
import { globalState } from '../statemanagement';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxEchartsModule } from 'ngx-echarts';
import { CdkTableModule } from '@angular/cdk/table';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { LoaderInterceptor } from './interceptors';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule, MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomRequestInterceptor } from "./interceptors/custom-request";
import { DatePipe } from '@angular/common';
import { StartsWithRouterLinkDirective } from './utils/startsWith/startsWith.directive';
import { MatTooltipModule } from "@angular/material/tooltip";
import { TooltipDirective } from "./components/table/tooltip-status/tooltip.directive";
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { LoginModule } from './containers/login/login.module';
import { FavoriteAssetModule } from './containers/favorite-asset/favorite-asset.module';
import { UnauthorizedModule } from './containers/unauthorized/unauthorized.module';
import { SettingPageModule } from './containers/setting-page/setting-page.module';
import { ConfigurationModule } from "./containers/configuration/configuration.module";
import { CardErrorModule } from './components/card-error/card-error.module';
import { UiGraphModule } from './components/ui-graph/ui-graph.module';
import { TabSelectorModule } from './components/tab-selector/tab-selector.module';
import { DateSelectionModule } from './components/date-selection/date-selection.module';
import { CrossPlotModule } from './components/cross-plot/cross-plot.module';
import { PerformanceDriftModule } from './components/performance-drift/performance-drift.module';
import { EnergyPerformanceModule } from './containers/compressors/components/energy-performance/energy-performance.module';
import { LoadingSpinnerModule } from './components/loading-spinner/loading-spinner.module';
import { OverviewKpisModule } from './components/overview-kpis/overview-kpis.module';
import { SharedModule } from './shared.module';
import { CompressorsContainerModule } from './containers/compressors/compressors.container.module';
import { OverviewGhgEmissionsModule } from './containers/general-dashboard/components/overview-ghg-emissions/overview-ghg-emissions.module';
import { AlertTableModule } from './containers/general-dashboard/components/alert-table/alert-table.module';
import { SidePanelModule } from './containers/general-dashboard/components/side-panel/side-panel.module';
import { DiscussionsModule } from './containers/general-dashboard/components/discussions/discussions.module';
import { GeneralDashboardContainerModule } from './containers/general-dashboard/general-dashboard.container.module';
import { PowerDataModule } from './containers/turbogenerators/components/power-data/power-data.module';
import { TurbogeneratorsContainerModule } from './containers/turbogenerators/turbogenerators.container.module';
import { PumpContainerModule } from './containers/pump/pump.container.module';
import { AdminModule } from './containers/admin/admin.module';
import { ToasterModule } from "./components/toaster/toaster.module";
import { FooterComponent } from './components/footer/footer.component';
import { FlaringContainerModule } from './containers/flaring/flaring-container.module';


export const FR_DATE_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({ declarations: [
        AppContainer,
        HeaderNavComponent,
        StartsWithRouterLinkDirective,
        PrivateLayoutContainer,
        FooterComponent,
        PublicLayoutContainer
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppContainer, MsalRedirectComponent], 
    imports: [BrowserModule,
        AppRoutingModule,
        CardErrorModule,
        UiGraphModule,
        AdminModule,
        GeneralDashboardContainerModule,
        TurbogeneratorsContainerModule,
        SharedModule,
        PumpContainerModule,
        PowerDataModule,
        OverviewGhgEmissionsModule,
        FlaringContainerModule,
        DiscussionsModule,
        TabSelectorModule,
        CompressorsContainerModule,
        EnergyPerformanceModule,
        LoadingSpinnerModule,
        OverviewKpisModule,
        SidePanelModule,
        DateSelectionModule,
        PerformanceDriftModule,
        CrossPlotModule,
        MsalModule,
        ToasterModule,
        NgxsModule.forRoot(globalState, {
            developmentMode: !environment.production,
            compatibility: {
                strictContentSecurityPolicy: true,
            },
        }),
        NgxsReduxDevtoolsPluginModule.forRoot({
            disabled: environment.production,
        }),
        NgxsLoggerPluginModule.forRoot({
            disabled: true,
        }),
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts'),
        }),
        CdkTableModule,
        AlertTableModule,
        ScrollingModule,
        NgxSkeletonLoaderModule,
        MatDatepickerModule,
        FormsModule,
        MatNativeDateModule,
        BrowserAnimationsModule,
        MatTooltipModule,
        TooltipDirective,
        ReactiveFormsModule,
        LoginModule,
        FavoriteAssetModule,
        UnauthorizedModule,
        SettingPageModule,
        ConfigurationModule], providers: [
        MsalGuard,
        DatePipe,
        MsalService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CustomRequestInterceptor,
            multi: true,
        },
        { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
        { provide: MAT_DATE_FORMATS, useValue: FR_DATE_FORMAT },
        { provide: DateAdapter, useClass: MomentDateAdapter },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule { }
