import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSandbox } from '../../app.sandbox';
import { TabItem } from '../../types';
import { cloneObject } from "../../utils/object-manipulation/object-manipulation";

@Component({
  selector: 'co2-flaring-container',
  templateUrl: './flaring-container.component.html',
  styleUrls: [ './flaring-container.component.scss' ]
})
export class FlaringContainerComponent {
  readonly FLARING_TYPE = "FLARE";

  equipmentNamesByType!: Map<string, string[]>;
  allEquipmentNames!: string[];
  subTabs!: TabItem[];
  activeTab: any;
  fpso = this.sb.getActiveFpso();
  affiliate = this.sb.getActiveAffiliate();
  selectedTab!: string;

  constructor(
    public sb: AppSandbox,
    private activatedRoute: ActivatedRoute,
    public router: Router) {
  }

  ngOnInit(): void {
    this.sb.getSubTabs(this.FLARING_TYPE).subscribe(tabs => {
      this.onGetTabs(tabs)
    });
    const queryParams = this.activatedRoute.snapshot.queryParams;
    this.activeTab = queryParams['flare-type'];
    this.selectedTab = `Flare_${ this.activeTab }`;
  };

  onGetTabs(tabs: TabItem[]) {
    this.equipmentNamesByType = new Map(tabs.map(tab => {
      return [ tab.equipmentType!, (tab?.subItemName?.length) ? tab.subItemName : [ tab.id ] ];
    }));
    this.allEquipmentNames = tabs.flatMap(i => i.subItemName && i.subItemName.length > 0 ? i.subItemName : [ i.id ]);
    this.subTabs = [
      ...(cloneObject(tabs) as TabItem[]).map(tab => {
        tab.id = tab.label!;
        return tab;
      }) ];
  }

  onSelectTab(flareType: any): void {
    this.router.navigate([ `/${ this.affiliate }/${ this.fpso }/flaring` ], { queryParams: { 'flare-type': flareType } });
    const selectedTab = this.subTabs.find(tab => tab.label === flareType);
    if (selectedTab && selectedTab.equipmentType) {
      this.selectedTab = selectedTab.equipmentType;
    }
    this.activeTab = flareType;
  }
}

