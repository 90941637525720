import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { FlaringContainerComponent } from './flaring-container.component';
import { DailyFlaringComponent } from './daily-flaring/daily-flaring.component';
import { MonthlyFlaringComponent } from './monthly-flaring/monthly-flaring.component';
import { UiGraphModule } from '../../components/ui-graph/ui-graph.module';
import { TabSelectorModule } from '../../components/tab-selector/tab-selector.module';
import { DateSelectionModule } from '../../components/date-selection/date-selection.module';

const routes: Routes = [
  { path: '', component: FlaringContainerComponent }
];



@NgModule({
  declarations: [FlaringContainerComponent, MonthlyFlaringComponent, DailyFlaringComponent],
  imports: [
    CommonModule,
    FormsModule,
    UiGraphModule,
    TabSelectorModule,
    DateSelectionModule,
    RouterModule.forChild(routes)
  ],
  exports: [FlaringContainerComponent, MonthlyFlaringComponent, DailyFlaringComponent]
})
export class FlaringContainerModule { }
