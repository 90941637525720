import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { EChartsOption } from 'echarts';
import { AppSandbox } from 'src/modules/app/app.sandbox';
import { CommonGraphsService } from 'src/modules/app/services/common-graphs/common-graphs.service';
import { FlaringService } from 'src/modules/app/services/flaring/flaring.service';
import { DEFAULT_YAXIS_INPUTS, TabItem } from 'src/modules/app/types';
import { DateTimePickerProperties } from 'src/modules/app/utils/date-time-picker/date-time-picker';
import { FlareType } from '../../general-dashboard/general-dashboard.container.const';
import { DEFAULT_FLARING_GRAPH } from 'src/modules/app/services/common-graphs/common-graphs.const';
import { Subscription } from "rxjs";
import { FlareConfig } from "../../../services/flaring/flaring-modal";

@Component({
  selector: 'co2-daily-flaring',
  templateUrl: './daily-flaring.component.html',
  styleUrls: [ './daily-flaring.component.scss' ]
})
export class DailyFlaringComponent implements OnDestroy {
  @Input() type: any;
  @Input() fpso: string = '';
  @Input() affiliate: string = '';
  allEquipmentNames!: string[];
  subTabs!: TabItem[];
  activeTab: any;
  suctionDischargePressureGraphInputs!: EChartsOption;
  suctionDischargePressureGraphInputsHourly!: EChartsOption;
  flowDailyInputs!: EChartsOption;
  flowHourlyInputs!: EChartsOption;
  noAvailableFlowDaily: boolean = false;
  noAvailableFlowHourly: boolean = false;
  noAvailableFlareDaily: boolean = false;
  noAvailableFlareHourly: boolean = false;
  datePickerProperties!: DateTimePickerProperties;
  subscriptions: Subscription[] = [];
  graphMetadata = [ { "LAE": { "color": "#2196F3", "yAxisName": "electrical power optimum (kW)", "yAxisUnit": "kW" } },
    { "X3/Over the norm": { "color": "#8c9fae", "yAxisName": "electrical power optimum (kW)", "yAxisUnit": "kW" } },
    { "Full Flare": { "color": "#FFCC80", "yAxisName": "electrical power optimum (kW)", "yAxisUnit": "kW" } } ]


  constructor(
    public sb: AppSandbox,
    private flaringService: FlaringService,
    private graphsService: CommonGraphsService,
    public router: Router) {
  }

  ngOnChanges() {
    this.subscriptions.push(
      this.flaringService.getConfig(this.affiliate, this.fpso, this.type).subscribe(config => {
        this.callFlaringApis(config);
      })
    );
  };

  private callFlaringApis(config: FlareConfig) {
    this.subscriptions.push(
      this.flaringService.getDailyFlaring(this.affiliate, this.fpso, this.type, FlareType.EMISSIONS_DAILY).subscribe((data: any) => {
        if (data.length) {
          this.suctionDischargePressureGraphInputs = this.buildGraph(config.upperThresholdCo2);
          this.suctionDischargePressureGraphInputs = { ...this.graphsService.updateLineGraph(this.suctionDischargePressureGraphInputs, data, 'CO2 emissions ktCO2/y', this.graphMetadata) };
        } else {
          this.noAvailableFlareHourly = true;
        }
      }),
      this.flaringService.getHourlyFlaring(this.affiliate, this.fpso, this.type, FlareType.EMISSIONS_HOURLY).subscribe((data: any) => {
        if (data.length) {
          this.suctionDischargePressureGraphInputsHourly = this.buildGraph(config.upperThresholdCo2);
          this.suctionDischargePressureGraphInputsHourly = { ...this.graphsService.updateLineGraph(this.suctionDischargePressureGraphInputsHourly, data, 'CO2 emissions ktCO2/y', this.graphMetadata) };
        } else {
          this.noAvailableFlareHourly = true;
        }
      }),
      this.flaringService.getDailyFlaring(this.affiliate, this.fpso, this.type, FlareType.FLOW_RATE_DAILY).subscribe((data: any) => {
        if (data.length) {
          this.flowDailyInputs = this.buildGraph(config.upperThreshold);
          this.flowDailyInputs = { ...this.graphsService.updateLineGraph(this.flowDailyInputs, data, 'Flow Rate kSm3/d', this.graphMetadata) };
        } else {
          this.noAvailableFlowDaily = true;
        }
      }),
      this.flaringService.getHourlyFlaring(this.affiliate, this.fpso, this.type, FlareType.FLOW_RATE_HOURLY).subscribe((data: any) => {
        if (data.length) {
          this.flowHourlyInputs = this.buildGraph(config.upperThreshold);
          this.flowHourlyInputs = { ...this.graphsService.updateLineGraph(this.flowHourlyInputs, data, 'Flow Rate kSm3/d', this.graphMetadata) };
        } else {
          this.noAvailableFlowHourly = true;
        }
      })
    );
  }

  private buildGraph(max: number) {
    return {
      ...DEFAULT_FLARING_GRAPH,
      yAxis: {
        ...DEFAULT_YAXIS_INPUTS,
        min: 0,
        max: max
      },
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
